const en = {
  hero_title1: "hi there!",
  hero_title2: `I’M <br/> <span class='serge-name'>SERGE MAURICE LOBRÉAU</span>`,
  hero_title3: `THE FOUNDER OF <span class='text-purple'>SAPIAN GROUP</span> & <span class='text-purple'>FLASH GROUP</span>`,
  get_started: "Get Started",
  read_more: "Read More",
  view_all: "View All",
  africa: "Africa",
  europe: "Europe",
  the_world: "The World",
  hero_desc:
    "If you have NEVER suffered starvation, you will NEVER understand our grit and why enough will NEVER Be enough.",
  video_section_title: "my videos",
  about_section_title: "about me",
  about_section_desc: `I am Serge Maurice Lobréau,Founder at SAPIAN and FLASH GROUP, I am designing disruptive products and services into the financial area To bring financial inclusion for the common good.`,
  about_section_africa_title: "The place I was born and have been shaped",
  about_section_africa_desc: `I was born in Mbuji-Mayi, which was formerly known as Zaïre. As a child, my African perspective saw our way of life as normal, but as I grew older and gained a European perspective, I realized how difficult and harsh life could be. Despite the challenges we faced, we were happy and content with the little we had. This taught me that no matter what difficulties life throws your way, smiling can make everything seem better.`,
  about_section_europe_title: "The place I Grew up and transformed",
  about_section_europe_desc:
    "When I was young in Africa, I used to believe that Europe was located in the sky. However, when I arrived at Charles de Gaulle Airport in 1989, the scenes on TV of people breaking the Berlin Wall, crying, and hugging left a lasting impression on me. I quickly realized that the ability to observe and learn quickly was crucial for survival.",
  about_section_world_title: "The place I am evolving",
  about_section_world_desc:
    "I am a Global Citizen, and the world is my home. I worked and lived on over five continents with a tropism in Africa, visiting 44 countries in 54! I consider myself a Global Citizen, and I feel at home in any part of the world. I have worked and lived on five continents, with a particular interest in Africa. I have traveled to 44 countries in total. This international experience has taught me to approach everything I do, especially in business, with a global perspective. I always try to create solutions that benefit the world at large. As such, everything I am doing, especially in business, has a global blueprint. And I am thinking as much as possible to build solutions for the greater good.",
  experience_section_title: "experiences",
  experience_section_desc:
    `I have over 15 years of experience in finance. I began my career in the hedge fund sector where I played an important role in selling major restructuring and derivatives-based financial products at a country level. However, I later decided to switch to the technology sector and worked for a well-known software company at their European HQ as a project manager. This experience provided me with a strong foundation for my career in technology. Eventually, I returned to the banking world where I worked with major international banks.<br/><br/>
    After I started a family, I moved back to France and worked for a Big 3 Bank. In this corporate finance role, I was responsible for tasks such as economic modeling, risk management, financial planning, and wealth management. I dealt with institutions and UHNWIs and managed over $2.5 billion in assets under management and over 800 corporate accounts.<br/><br/>
    Later on, I was introduced to network marketing . This led me to start my entrepreneurial journey and move to Gold mines from a request made by a former client. I reconnected with my roots and relatives in the DRC to begin my Gold trading business. From there, I built an investment holding company and diversified into real estate, cryptocurrency, app development, and various other businesses.
    `,
  company_section_title: "Embarking on Entrepreneurial Ventures: My Companies and Experiences",
  company_section_sapian_desc: `Sapian Group is the holding company or various wealth management, asset management, sales channel services and Blockchain associated businesses within the group. The group is licensed in Estonia, Lithuania, Singapore holding eWallet, Exchange and Financial institution licences.`,
  company_section_flash_desc: `Our solution provides utility pegged coin with a controlled volatility,and exchanger services with the lowest cost in the market. Our technology combines regular financial services and blockchain-powered finance technology to bring users an efficient solution and empowerment in their daily financial transactions.`,
  service_section_title: "services",
  service_item_title1: "Taxes optimization<br/>strategy",
  service_item_title2: "Incorporation<br/>onshore/offshore",
  service_item_title3: "OTC operator<br/>agent",
  service_item_title4: "Branding/Visual<br/>identity",
  service_item_title5: "Cryptomonial<br/>engineering",
  book_section_title: "my books",
  book_section_title1:
    "Please Make the payment, I am waiting for last a month!",
  book_section_title2:
    "Please Make the payment, I am waiting for last a month!",
  book_section_title3:
    "Please Make the payment, I am waiting for last a month!",
  book_section_title4:
    "Please Make the payment, I am waiting for last a month!",
  article_section_title: "My Articles",
  footer_text: "2019-2024 Serge Maurice Lobréau",
};
export default en;
