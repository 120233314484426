import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      component="footer"
      py={4}
      textAlign="center"
      sx={{
        borderTop: "1px solid #c6c6da",
      }}
    >
      <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.6)" }}>
        2019 - {currentYear} Serge Maurice Lobréau
      </Typography>
    </Box>
  );
}
